import { useTheme } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import {
    AclNamespaces,
    AclPermissions,
    AggregationMethod,
    ChartLayout,
    CustomBarChartWidgetConfig,
    LegendLayout,
    MeasureFieldNames,
    RechartsData,
    SINGLE_PIVOT_DATA_KEY,
    Sort,
    TooltipLayout,
    UserStatus,
    mapPivotToRecharts,
    validateCustomBarChartWidgetConfig,
} from "api-shared";
import type { TFunction } from "i18next";
import { isEqual, isNumber, sortBy, values } from "lodash";
import { useCallback, useState } from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, ReferenceLine, Tooltip, XAxis, XAxisProps, YAxis, YAxisProps } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import { TreeNavigationBreadcrumbs } from "../../../components/TreeNavigationBreadcrumbs";
import HorizontalBarChartSkeleton from "../../../components/loading/HorizontalBarChartSkeleton";
import { useUserHasPermissionQuery } from "../../../domain/permissions";
import { usePivotData, usePivotFields } from "../../../domain/reporting";
import { useAllUsers } from "../../../domain/users";
import useCurrency, { CurrencyFormatter } from "../../../hooks/useCurrency";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import { useLanguage } from "../../../hooks/useLanguage.ts";
import useTimezone from "../../../hooks/useTimezone.tsx";
import { withPercentage } from "../../../lib/formatters.ts";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-vertical-bar.svg";
import { stripPxSuffix } from "../../../styles/utils";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetError from "../WidgetError";
import WidgetNoData from "../WidgetNoData";
import AngledXAxisTick from "../charts/AngledXAxisTick";
import { AxisTick } from "../charts/AxisTick";
import { AVG_COUNT_CHIP_WIDTH, AVG_POTENTIAL_CHIP_WIDTH } from "../charts/DeltaChip.tsx";
import { DeltaLabelList } from "../charts/DeltaLabelList.tsx";
import { LinkableAxisTick } from "../charts/LinkableAxisTick";
import OutlineSvgFilter, { OUTLINE_FILTER_ID } from "../charts/OutlineSvgFilter.tsx";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import SumLabelList from "../charts/SumLabelList.tsx";
import { useDashboardColors, useLegendProps, useTooltipProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import {
    CalculatedStackKeys,
    DELETED_USER_ID,
    calculateFieldOptionsWithDeletedIds,
    combineDeletedUsersData,
    getDifferenceToTarget,
    getOvershootToTarget,
} from "../utils.ts";
import ChartWidgetRoot from "./ChartWidgetRoot";
import CustomBarChartWidgetConfigForm, { CustomBarChartSorting } from "./CustomBarChartWidgetConfigForm";
import DrilldownDialog from "./DrilldownDialog";
import { Drilldown } from "./DrilldownTable.tsx";
import { ExtendableCircleLegend } from "./ExtendableCircleLegend";
import { useFieldOptions, type FieldOption } from "./useFieldOptions";
import useTreeAggregation from "./useTreeAggregation";

/**
 * Characters in SVG <text> elements in Recharts have an average length of 7px.
 */
const AXIS_AVERAGE_CHARACTER_WIDTH = 7;

/**
 * Number of spacing units for the y-axis.
 */
const Y_AXIS_WIDTH_UNITS = 36;

const X_AXIS_HEIGHT_UNITS = 20;

function useLabelAxisProps(
    barType: ChartLayout,
    firstFieldOptionsMap: Map<string, string>,
    isLabelClickable?: (id: number) => boolean,
    onClick?: (id: number) => void,
    showAllLabels?: boolean,
    isHighlighted?: (id: number) => boolean,
) {
    const theme = useTheme();
    const maxNumberOfCharacters =
        stripPxSuffix(theme.spacing(barType === ChartLayout.Horizontal ? Y_AXIS_WIDTH_UNITS : X_AXIS_HEIGHT_UNITS)) /
        AXIS_AVERAGE_CHARACTER_WIDTH;

    const TickComponent = barType === ChartLayout.Vertical ? AngledXAxisTick : LinkableAxisTick;

    const tick = (
        <TickComponent
            maxCharacters={maxNumberOfCharacters}
            isLabelClickable={isLabelClickable}
            onClick={onClick}
            isHighlighted={isHighlighted !== undefined ? (value: any) => isHighlighted(+value) : undefined}
        />
    );

    return {
        dataKey: "key",
        tick: tick,
        tickFormatter: (value: any) => firstFieldOptionsMap.get(String(value)) ?? "",
        type: "category",
        interval: showAllLabels ? 0 : undefined,
        width: stripPxSuffix(theme.spacing(Y_AXIS_WIDTH_UNITS)),
        height: stripPxSuffix(theme.spacing(X_AXIS_HEIGHT_UNITS)),
        minTickGap: 0,
    } satisfies YAxisProps;
}
function useNumberAxisProps(
    barType: ChartLayout,
    aggregation: AggregationMethod,
    formatCurrencyShort: CurrencyFormatter,
    axisMinValue: number | null,
    axisMaxValue: number | null,
    showValuesAsPercentage: boolean,
) {
    const theme = useTheme();
    const language = useLanguage();

    const tick = <AxisTick textAnchor={barType === ChartLayout.Vertical ? "end" : "middle"} />;

    let formatter: CurrencyFormatter | ((value: number) => number | string) = formatCurrencyShort;
    if (aggregation === AggregationMethod.Count) {
        formatter = (value: number) => value;
    }
    if (showValuesAsPercentage) {
        formatter = (value: number) => {
            return withPercentage(value * 100, language, 0);
        };
    }

    return {
        axisLine: false,
        tickLine: false,
        domain: [
            (dataMin: number) => Math.min(dataMin, axisMinValue !== null && !showValuesAsPercentage ? axisMinValue : 0),
            (dataMax: number) => {
                return Math.max(dataMax, axisMaxValue !== null && !showValuesAsPercentage ? axisMaxValue : 0);
            },
        ],
        tick: tick,
        tickFormatter: (value) => formatter(value) ?? value,
        width: stripPxSuffix(theme.spacing(15)),
        // Using "linear" for scaling causes percentages to scale as 20% -> 40% -> 60% -> 80% -> 100% vs 25% -> 50% -> 75% -> 100%
        scale: showValuesAsPercentage ? "auto" : "linear",
        type: "number",
    } satisfies XAxisProps;
}

function getOrderedStackOptions(
    stackPivotField: string | null,
    stackFieldOptions: FieldOption[],
    hasStackPivotField: boolean,
    translate: TFunction,
    aggregation: AggregationMethod,
) {
    if (!hasStackPivotField) {
        return [
            {
                value: SINGLE_PIVOT_DATA_KEY,
                label: translate(
                    aggregation === AggregationMethod.Count
                        ? translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AGGREGATION
                        : translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_POTENTIAL,
                ),
            },
        ];
    }

    // inverse the order of stack groups if stacked by level to display the last gate task ("completed") nearest to the 0-line of the chart
    if (stackPivotField === MeasureFieldNames.CurrentGateTaskConfigId) {
        return stackFieldOptions.toReversed();
    }

    return stackFieldOptions;
}

type BarItem = {
    key: string;
    [CalculatedStackKeys.DIFFERENCE_TO_TARGET]?: number;
    [CalculatedStackKeys.TARGET]?: number;
    [CalculatedStackKeys.OVERSHOOT]?: number;
};

type CustomBarChartWidgetProps = IWidgetContentProps & { defaultSelectedTreeNodeId?: number };

export type CustomBarChartConfigFormData = { value: number; fields: Record<string, unknown> }[];

const CustomBarChartWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
    defaultSelectedTreeNodeId,
}: CustomBarChartWidgetProps) => {
    const {
        aggregation,
        metric,
        firstPivotField: barPivotField,
        secondPivotField: stackPivotField,
        scope,
        filter,
        sort,
        orderBy,
        barDisplayLayout,
        showSums,
        axisMaxCountValue,
        axisMinCountValue,
        axisMinPotentialValue,
        axisMaxPotentialValue,
        targets,
        showReferenceValues,
        referenceValues,
        showRelativeRepresentation,
        defaultStartTreeNodeIds,
    } = widget.config as CustomBarChartWidgetConfig;
    const [chartWidth, setChartWidth] = useState(0);

    const { formatShortDateTime } = useTimezone();

    const hasStackPivotField = stackPivotField !== null;

    // Check that filter is allowed
    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = Boolean(validate?.(filter));

    // Check that pivot fields are allowed
    const hasPersonPivotField = barPivotField === MeasureFieldNames.AssignedToId || stackPivotField === MeasureFieldNames.AssignedToId;
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const hasValidPivotFields = !(hasPersonPivotField && !hasPersonFilterPermissionQuery.data?.hasPermission);

    // Combine all checks
    const isValidConfig = hasValidFilter && hasValidPivotFields;

    const pivotFields = hasStackPivotField ? [barPivotField, stackPivotField] : [barPivotField];
    const pivotQuery = usePivotData({
        aggregation,
        metric,
        fields: pivotFields,
        scope,
        filter,
        enabled: isInView && isValidConfig,
    });

    const hasErrorState = !pivotQuery.isFetching && validate !== undefined && !isValidConfig;

    const fieldsQuery = usePivotFields(isInView);

    const barFieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: barPivotField }) ?? [];

    const stackFieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: stackPivotField ?? undefined }) ?? [];

    const deletedUserIds = useAllUsers()
        .filter((user) => user.status === UserStatus.STATUS_DELETED)
        .map(({ id }) => id);

    const { options: computedBarFieldOptions, deletedIds: deletedBarIds } = hasValidPivotFields
        ? calculateFieldOptionsWithDeletedIds(barPivotField, barFieldOptions, deletedUserIds)
        : { options: barFieldOptions, deletedIds: undefined };

    const { options: computedStackFieldOptions, deletedIds: deletedStackIds } = hasValidPivotFields
        ? calculateFieldOptionsWithDeletedIds(stackPivotField, stackFieldOptions, deletedUserIds)
        : { options: stackFieldOptions, deletedIds: undefined };

    const deletedDrilldownIds = deletedBarIds ?? deletedStackIds ?? [];

    // inverse the order of stack groups if stacked by level to display the last gate task ("completed") nearest to the 0-line of the chart
    const orderedStackOptions = getOrderedStackOptions(
        stackPivotField,
        computedStackFieldOptions,
        hasStackPivotField,
        translate,
        aggregation,
    );

    const barFieldOptionsMap = new Map(computedBarFieldOptions.map(({ value, label }) => [String(value), label]));

    const { formatCurrencyShort, formatCurrency } = useCurrency();
    const formatterTooltip = aggregation === AggregationMethod.Count ? (value: number | string) => value : formatCurrency;

    const currentConfig = {
        aggregation,
        firstPivotField: barPivotField,
        filter,
        scope,
    };
    const hasValidReferenceValues = referenceValues !== null && isEqual(currentConfig, referenceValues.config);
    const renderReferenceValues = showSums && showReferenceValues && hasValidReferenceValues;

    const theme = useTheme();
    const tooltipProps = useTooltipProps({
        chartHeight: stripPxSuffix(theme.spacing()),
        tooltipLayout: barDisplayLayout === ChartLayout.Vertical ? TooltipLayout.Reversed : TooltipLayout.Normal,
        showDifferenceToTarget: targets != null && aggregation === AggregationMethod.Sum,
        showPercentageShares: showRelativeRepresentation,
    });
    const colors = useDashboardColors();
    const legendPropsSpacing = barDisplayLayout === ChartLayout.Vertical && showSums ? 2 + Number(renderReferenceValues) * 4 : 0;
    const legendProps = useLegendProps(legendPropsSpacing);

    const [drilldown, setDrilldown] = useState<Drilldown>();

    const treeNavigation = useTreeAggregation({
        fieldDefinitions: fieldsQuery.data ?? {},
        fieldName: barPivotField,
        pivotData: pivotQuery.data,
        defaultSelectedNodeId: defaultStartTreeNodeIds?.[barPivotField] ?? defaultSelectedTreeNodeId,
    });
    const axisMinValue = aggregation === AggregationMethod.Count ? axisMinCountValue : axisMinPotentialValue;
    const axisMaxValue = aggregation === AggregationMethod.Count ? axisMaxCountValue : axisMaxPotentialValue;
    const numberAxis = useNumberAxisProps(
        barDisplayLayout,
        aggregation,
        formatCurrencyShort,
        axisMinValue,
        axisMaxValue,
        showRelativeRepresentation && aggregation === AggregationMethod.Sum,
    );
    const labelAxis = useLabelAxisProps(
        barDisplayLayout,
        barFieldOptionsMap,
        treeNavigation?.isNavigatable,
        treeNavigation?.setSelectedNode,
        treeNavigation != null,
        treeNavigation?.isSelectedNode,
    );

    const handleResize = useCallback((width: number) => {
        setChartWidth(width);
    }, []);

    if (!validateCustomBarChartWidgetConfig(widget.config)) {
        return null;
    }

    const pivotData = combineDeletedUsersData(treeNavigation?.mappedData ?? pivotQuery.data ?? [], deletedUserIds);
    const data = mapPivotToRecharts(pivotData, barPivotField, stackPivotField);
    const referenceValuesData = combineDeletedUsersData(
        treeNavigation?.mappedReferenceValues ?? treeNavigation?.mappedData ?? pivotQuery.data ?? [],
        deletedUserIds,
    );

    const stackKeysInUse = hasStackPivotField
        ? new Set(pivotData.map(({ fields }) => String(fields[stackPivotField])))
        : new Set([SINGLE_PIVOT_DATA_KEY]);

    const barSegments = getBarSegments(orderedStackOptions, colors, stackKeysInUse);

    const isTitle = orderBy === CustomBarChartSorting.TitleAsc || orderBy === CustomBarChartSorting.TitleDesc;
    const isEffect = orderBy === CustomBarChartSorting.AggregationAsc || orderBy === CustomBarChartSorting.AggregationDesc;
    const isDefined = orderBy === CustomBarChartSorting.FieldOrderAsc || CustomBarChartSorting.FieldOrderDesc;

    const sortingData = (data: RechartsData[]) => {
        if (isTitle) {
            const dataWithLabels = data.map((item) => ({
                key: item.key,
                label: barFieldOptionsMap.get(item.key) ?? "",
            }));
            dataWithLabels.sort((a, b) => (a.label > b.label ? 1 : -1));
            const sortedData = sortBy(data, ({ key }) => dataWithLabels.findIndex((o) => o.key === key));
            if (sort === Sort.DESCENDING) {
                sortedData.reverse();
            }
            return sortedData;
        }
        if (isEffect) {
            const sortedData = sortBy(data, (item) =>
                values(item)
                    .filter((value) => isNumber(value))
                    .reduce((sum, currentValue) => sum + currentValue),
            );
            if (sort === Sort.DESCENDING) {
                sortedData.reverse();
            }
            return sortedData;
        }
        if (isDefined) {
            // Here we want to ensure that for tree fields the parent node is always at the top at deeper levels
            const sortedData = sortBy(data, ({ key }) => barFieldOptions.findIndex((o: FieldOption) => o.value === key));
            if (sort === Sort.DESCENDING) {
                sortedData.reverse();
            }
            const dataKeys = sortedData.map((item) => Number(item.key));
            const dataFieldOptions = barFieldOptions.filter((o) => dataKeys.includes(Number(o.value)));
            const parentIds = dataFieldOptions.map((opts) => opts.parentId);
            const parentNodeIdx = sortedData.findIndex((o) => parentIds.includes(Number(o.key)));
            if (parentNodeIdx !== -1) {
                const [parentNode] = sortedData.splice(parentNodeIdx, 1);
                sortedData.unshift(parentNode);
            }
            return sortedData;
        }
        return data;
    };

    const sortedData = sortingData(data);
    const dataWithTargets: BarItem[] = [...sortedData];
    if (targets?.[barPivotField] != null && aggregation === AggregationMethod.Sum) {
        // For all specified targets, update the data with the target value and the difference to the target
        // For entries that are not in the data, add them with the target value and the difference to the target
        Object.entries(targets[barPivotField]).forEach(([key, value]) => {
            const target = value ?? undefined;

            const existingEntry = sortedData.find((item: BarItem) => item.key === key);
            if (target != null && existingEntry != null) {
                const replaceIndex = dataWithTargets.findIndex((item: BarItem) => item.key === key);
                dataWithTargets[replaceIndex] = {
                    ...existingEntry,
                    target,
                    [CalculatedStackKeys.DIFFERENCE_TO_TARGET]: getDifferenceToTarget(target, existingEntry),
                    [CalculatedStackKeys.OVERSHOOT]: getOvershootToTarget(target, existingEntry),
                };
            }
            if (target != null && existingEntry == null) {
                dataWithTargets.push({
                    key,
                    [CalculatedStackKeys.TARGET]: target,
                    [CalculatedStackKeys.DIFFERENCE_TO_TARGET]: target,
                    [CalculatedStackKeys.OVERSHOOT]: 0,
                });
            }
        });
    }

    const hasEmptyData = dataWithTargets.length === 0;

    const isVerticalLayout = barDisplayLayout === ChartLayout.Vertical;
    const xAxis = isVerticalLayout ? labelAxis : numberAxis;
    const yAxis = isVerticalLayout ? numberAxis : labelAxis;
    const layout = isVerticalLayout ? ChartLayout.Horizontal : ChartLayout.Vertical;
    const legendLayout = isVerticalLayout ? LegendLayout.Reversed : LegendLayout.Normal;

    function openDrillDown({ key, tooltipPayload: [{ dataKey }] }: any): void {
        // Recharts does not provide better typing for the onClick callback of Bars
        const barValue: string | number = key;
        const barValueId = Number(barValue);
        const stackValue: string | number = dataKey;

        let barPivotValues: (string | number)[];
        if (Number.isNaN(barValueId)) {
            // force NOT SET in case of null value
            barPivotValues = ["null"];
        } else if (treeNavigation != null) {
            barPivotValues =
                barPivotField === MeasureFieldNames.AssignedToId && deletedDrilldownIds && barValueId === DELETED_USER_ID
                    ? deletedDrilldownIds
                    : treeNavigation.getSubtreeIds(barValueId);
        } else {
            barPivotValues =
                barPivotField === MeasureFieldNames.AssignedToId && deletedDrilldownIds && barValueId === DELETED_USER_ID
                    ? deletedDrilldownIds
                    : [String(barValueId)];
        }

        const newDrilldown: Drilldown = { [barPivotField]: barPivotValues };
        if (hasStackPivotField) {
            newDrilldown[stackPivotField] =
                stackPivotField === MeasureFieldNames.AssignedToId && deletedDrilldownIds && Number(stackValue) === DELETED_USER_ID
                    ? deletedDrilldownIds
                    : [stackValue];
        }
        return setDrilldown(newDrilldown);
    }

    const barSegmentKeys = barSegments.map((segment) => segment.key);
    const LabelListComponent = aggregation === AggregationMethod.Sum ? ShortNoCodeCurrencyLabelList : SumLabelList;

    const hasItemsWithTarget =
        targets != null && dataWithTargets.some((item: BarItem) => item[CalculatedStackKeys.DIFFERENCE_TO_TARGET] != null);

    // ReCharts default margin is 5/5/5/5
    // minus 10 so that we have as little white space as possible
    const sumSpacing = AggregationMethod.Sum
        ? AVG_POTENTIAL_CHIP_WIDTH + Number(renderReferenceValues) * AVG_POTENTIAL_CHIP_WIDTH - 10
        : AVG_COUNT_CHIP_WIDTH;
    const margin = { top: 5, left: 5, right: showSums && !isVerticalLayout ? sumSpacing : 5, bottom: 5 };

    function handleConfigSave(result: { name?: string; description?: string | null; config?: Record<string, unknown> }): void {
        onConfigSave(result);
        const defaultStartTreeNodeId = (result.config as CustomBarChartWidgetConfig)?.defaultStartTreeNodeIds?.[barPivotField];
        if (treeNavigation !== null && defaultStartTreeNodeId !== undefined) {
            treeNavigation.setSelectedNode(defaultStartTreeNodeId);
        }
    }

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog<CustomBarChartConfigFormData>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={handleConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateCustomBarChartWidgetConfig}
                FormComponent={CustomBarChartWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
                data={referenceValuesData}
            />
            {drilldown !== undefined && (
                <DrilldownDialog
                    open
                    onClose={() => setDrilldown(undefined)}
                    dataKey={`widget${widget.id}`}
                    drilldown={drilldown}
                    filter={filter}
                    scope={scope}
                />
            )}
            {!pivotQuery.isSuccess && !pivotQuery.isError ? <HorizontalBarChartSkeleton /> : null}
            {pivotQuery.isError || hasErrorState ? <WidgetError /> : null}
            {pivotQuery.isSuccess && hasEmptyData ? <WidgetNoData src={EmptyVerticalBarChartIllustration} /> : null}
            {pivotQuery.isSuccess && !hasEmptyData && (
                <ResponsiveContainer onResize={handleResize}>
                    <ComposedChart
                        data={dataWithTargets}
                        layout={layout}
                        stackOffset={showRelativeRepresentation ? "expand" : "sign"}
                        margin={margin}
                    >
                        <CartesianGrid strokeDasharray={4} horizontal={isVerticalLayout} vertical={!isVerticalLayout} />
                        <Legend
                            {...legendProps}
                            content={
                                treeNavigation != null ? (
                                    <ExtendableCircleLegend
                                        legendLayout={legendLayout}
                                        deltaTimestamp={renderReferenceValues ? formatShortDateTime(referenceValues.timestamp) : null}
                                        hiddenStacks={[CalculatedStackKeys.OVERSHOOT, CalculatedStackKeys.DIFFERENCE_TO_TARGET]}
                                    >
                                        <TreeNavigationBreadcrumbs
                                            selectedPath={treeNavigation.selectedPath}
                                            onSelect={treeNavigation.setSelectedNode}
                                            rootLabel={translate(barPivotField)}
                                        />
                                    </ExtendableCircleLegend>
                                ) : (
                                    <ExtendableCircleLegend
                                        legendLayout={legendLayout}
                                        deltaTimestamp={renderReferenceValues ? formatShortDateTime(referenceValues.timestamp) : null}
                                        hiddenStacks={[CalculatedStackKeys.OVERSHOOT, CalculatedStackKeys.DIFFERENCE_TO_TARGET]}
                                    />
                                )
                            }
                        />
                        <Tooltip
                            {...tooltipProps}
                            formatter={(value: number | string) => formatterTooltip(value) ?? value}
                            labelFormatter={(value: number) => barFieldOptionsMap.get(String(value)) ?? ""}
                        />
                        {barSegments.map(({ key, color, label }) => (
                            <Bar
                                name={label}
                                key={key}
                                dataKey={key}
                                stackId="0"
                                fill={color}
                                legendType="circle"
                                style={{ cursor: "pointer" }}
                                onClick={openDrillDown}
                                xAxisId={isVerticalLayout ? "default" : undefined}
                                yAxisId={!isVerticalLayout ? "default" : undefined}
                            >
                                {showSums ? (
                                    <LabelListComponent
                                        stackDataKeys={barSegmentKeys}
                                        chartWidth={chartWidth}
                                        yAxisPosition={yAxis.width}
                                        isHorizontal={!isVerticalLayout}
                                        isRelativeRepresentation={showRelativeRepresentation}
                                    />
                                ) : null}
                                {renderReferenceValues ? (
                                    <DeltaLabelList
                                        stackDataKeys={barSegmentKeys}
                                        referenceValues={referenceValues}
                                        treeData={treeNavigation?.treeData}
                                        selectedPath={treeNavigation?.selectedPath}
                                        chartWidth={chartWidth}
                                        isVerticalLayout={isVerticalLayout}
                                        isRelativeRepresentation={showRelativeRepresentation}
                                    />
                                ) : null}
                            </Bar>
                        ))}

                        {/* Special case for relative representation (stack offset = expand): We need to add a hidden "diff to target" stack to reach the real 100% value if the target is not reached yet. 
                        Otherwise the bar will have full height because the sum value will be the 100% mark. In "expand" mode, stacks on different axis will not expand the axis domain of the opposite axis. */}
                        {hasItemsWithTarget && showRelativeRepresentation ? (
                            <Bar
                                dataKey={CalculatedStackKeys.DIFFERENCE_TO_TARGET}
                                stackId="0"
                                fill="none"
                                xAxisId={isVerticalLayout ? "default" : undefined}
                                yAxisId={!isVerticalLayout ? "default" : undefined}
                                visibility="hidden"
                            >
                                {showSums ? (
                                    <LabelListComponent
                                        stackDataKeys={barSegmentKeys}
                                        chartWidth={chartWidth}
                                        yAxisPosition={yAxis.width}
                                        isHorizontal={!isVerticalLayout}
                                        isRelativeRepresentation={showRelativeRepresentation}
                                    />
                                ) : null}
                            </Bar>
                        ) : null}

                        <XAxis {...xAxis} xAxisId={isVerticalLayout ? "default" : undefined} />
                        <YAxis yAxisId={!isVerticalLayout ? "default" : undefined} {...yAxis} />

                        {hasItemsWithTarget ? (
                            <>
                                <OutlineSvgFilter />

                                {/* Define a second (hidden) bar to be able to display target stacks on top of normal existing bars */}
                                {isVerticalLayout ? <XAxis {...xAxis} xAxisId="target" hide /> : <YAxis {...yAxis} yAxisId="target" hide />}

                                <Bar
                                    name={translate(translationKeys.VDLANG_DASHBOARDS_TARGET_LABEL)}
                                    dataKey={CalculatedStackKeys.TARGET}
                                    xAxisId={isVerticalLayout ? "target" : undefined}
                                    yAxisId={!isVerticalLayout ? "target" : undefined}
                                    fill="none"
                                    style={{
                                        filter: `url(#${OUTLINE_FILTER_ID})`,
                                    }}
                                    stroke={blueGrey[800]}
                                    strokeDasharray="4 2"
                                    strokeWidth={2}
                                    legendType="circle"
                                    stackId="1"
                                />

                                {/* For the relative representation we need to add a stack with the "over shoot" because the relative value computation 
                                is independent for each axis and a axis with only a target stack will always be 100%. In "expand" mode, stacks on different axis will not expand the axis domain of the opposite axis. */}
                                {showRelativeRepresentation ? (
                                    <Bar
                                        dataKey={CalculatedStackKeys.OVERSHOOT}
                                        xAxisId={isVerticalLayout ? "target" : undefined}
                                        yAxisId={!isVerticalLayout ? "target" : undefined}
                                        fill="none"
                                        stackId="1"
                                        visibility="hidden"
                                    />
                                ) : null}
                            </>
                        ) : null}

                        <ReferenceLine
                            xAxisId={isVerticalLayout ? "default" : undefined}
                            yAxisId={!isVerticalLayout ? "default" : undefined}
                            y={isVerticalLayout ? 0 : undefined}
                            x={isVerticalLayout ? undefined : 0}
                            stroke={grey[600]}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </ChartWidgetRoot>
    );
};

export default CustomBarChartWidget;
