import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { DashboardDto, DuplicateDashboardRequest, zCreateDashboardRequestBody } from "api-shared";
import { FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useDuplicateDashboardMutation } from "../../../domain/dashboards";
import { tryTranslate } from "../../../lib/translate";
import { translationKeys } from "../../../translations/main-translations";

interface DuplicateDashboardDialogProps {
    open: boolean;
    onClose: () => void;
    onDashboardDuplicated?: (data: DashboardDto) => void;
    dashboard: DashboardDto;
}

const DuplicateDashboardDialog = ({ open, onClose, onDashboardDuplicated, dashboard }: DuplicateDashboardDialogProps) => {
    const { t: translate } = useTranslation();

    const currentDashboardName = translate(translationKeys.VDLANG_DASHBOARDS_DUPLICATE_LABEL, {
        dashboard: tryTranslate(translate, dashboard.name),
    });
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<DuplicateDashboardRequest>({
        mode: "onChange",
        resolver: zodResolver(zCreateDashboardRequestBody),
        defaultValues: {
            name: currentDashboardName,
        },
    });

    const duplicateDashboardMutation = useDuplicateDashboardMutation({ onSuccess: onDashboardDuplicated });

    const onSubmit = handleSubmit(({ name }) => {
        duplicateDashboardMutation.mutate({ dashboardId: dashboard.id, name });
        onClose();
    });

    const errorText = (errors: FieldError | undefined) => {
        if (errors === undefined) {
            return errors;
        }
        return errors.type?.includes("too_big")
            ? translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME_LENGTH_ERROR)
            : translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME_ERROR);
    };

    return (
        <ActionItemDialog
            title={translate(translationKeys.VDLANG_DASHBOARDS_DUPLICATE)}
            primary={translationKeys.VDLANG_DASHBOARDS_CREATE_BUTTON}
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            open={open}
            onClose={onClose}
            translate={translate}
        >
            <Form onSubmit={onSubmit}>
                <TextField
                    label={translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME)}
                    margin="dense"
                    fullWidth
                    autoFocus
                    error={errors.name !== undefined}
                    helperText={errorText(errors.name ? errors.name : undefined)}
                    {...register("name")}
                />
            </Form>
        </ActionItemDialog>
    );
};

export default DuplicateDashboardDialog;
