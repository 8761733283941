import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AclPermissions,
    BasicMessageDto,
    CreateDashboardRequestBody,
    CreateWidgetRequestBodyDto,
    CreateWidgetRequestParamsDto,
    DashboardDto,
    DashboardLayout,
    DeleteDashboardRequestParams,
    DuplicateDashboardRequest,
    ErrorConstantKeys,
    GetDashboardsResponseDto,
    LatestViewedDashboardsDto,
    PermissionBasicRequestParams,
    SimplePermissionsDto,
    UpdateDashboardRequest,
    UpdateWidgetRequestBodyDto,
    UpdateWidgetRequestParamsDto,
    WidgetDto,
    WidgetListDto,
} from "api-shared";
import { orderBy } from "lodash";
import { useEffect } from "react";
import { trackEvent } from "../infrastructure/tracking";
import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from "../lib/api";

interface QueryPermissions {
    permission: AclPermissions;
    dashboardId: number;
}

export const DashboardsQueryKeys = {
    all: ["dashboards"] as const,
    viewed: ["dashboards", "viewed"] as const,
    allPermissions: ["dashboards", "permissions"] as const,
    entityPermissions: (input: QueryPermissions) => [...DashboardsQueryKeys.allPermissions, "entity", input] as const,
};

export const useDashboards = () => {
    return useQuery({
        queryKey: DashboardsQueryKeys.all,
        queryFn: async ({ signal }) => {
            const dashboards = await apiGet<GetDashboardsResponseDto>("dashboards", { signal });
            return orderBy(dashboards, ["visibility", "name"]);
        },
    });
};

type CreateWidgetProps = CreateWidgetRequestParamsDto & CreateWidgetRequestBodyDto;
export const useAddDashboardWidgetMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, ...payload }: CreateWidgetProps) => apiPost<WidgetDto>(`dashboards/${dashboardId}/widgets`, payload),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);
        },
    });
};

interface IRemoveDashboardWidgetInput {
    dashboardId: number;
    widgetId: number;
}

export const useRemoveDashboardWidgetMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, widgetId }: IRemoveDashboardWidgetInput) => apiDelete(`dashboards/${dashboardId}/widgets/${widgetId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);
        },
    });
};

type UpdateWidgetProps = UpdateWidgetRequestParamsDto & { changes: UpdateWidgetRequestBodyDto };

export const useUpdateDashboardWidgetMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, widgetId, changes }: UpdateWidgetProps) =>
            apiPatch(`dashboards/${dashboardId}/widgets/${widgetId}`, changes),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);
        },
    });
};

interface ISaveDashboardLayoutInput {
    dashboardId: number;
    layout: DashboardLayout;
}
export const useSaveDashboardLayoutMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, layout }: ISaveDashboardLayoutInput) =>
            apiPut<WidgetListDto>(`dashboards/${dashboardId}/layout`, layout),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);
        },
    });
};

interface CreateDashboardMutationOptions {
    onSuccess?: (data: DashboardDto) => void;
}
interface DuplicateDashboardMutationOptions {
    onSuccess?: (data: DashboardDto) => void;
}

export const useCreateDashboardMutation = ({ onSuccess }: CreateDashboardMutationOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (body: CreateDashboardRequestBody) => apiPost<DashboardDto>("dashboards", body),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(DashboardsQueryKeys.all);
            onSuccess?.(data);
            trackEvent({ category: "Dashboard", action: "Dashboard Created" });
        },
    });
};

export const useUpdateDashboardMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, ...body }: UpdateDashboardRequest) => apiPatch<DashboardDto>(`dashboards/${dashboardId}`, body),
        onSuccess: (_, { name }) => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);

            name !== undefined && trackEvent({ category: "Dashboard", action: "Dashboard Updated" });
        },
    });
};

export const useDeleteDashboardMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId }: DeleteDashboardRequestParams) => apiDelete<DashboardDto>(`dashboards/${dashboardId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.all);
            trackEvent({ category: "Dashboard", action: "Dashboard Deleted" });
        },
    });
};

export const useDuplicateDashboardMutation = ({ onSuccess }: DuplicateDashboardMutationOptions) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ dashboardId, ...body }: DuplicateDashboardRequest) =>
            apiPost<DashboardDto>(`dashboards/${dashboardId}/duplicate`, body),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(DashboardsQueryKeys.all);
            onSuccess?.(data);
            trackEvent({ category: "Dashboard", action: "Dashboard Duplicated" });
        },
    });
};

export const useGetDashboardPermissions = ({ permission, dashboardId, enabled = true }: QueryPermissions & { enabled: boolean }) => {
    return useQuery({
        queryKey: DashboardsQueryKeys.entityPermissions({ permission, dashboardId }),
        queryFn: ({ signal }) => apiPost<SimplePermissionsDto>(`dashboards/${dashboardId}/permissions`, { permission }, { signal }),
        enabled,
    });
};

export const useDashboardPermissionsAddGroup = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => apiPut(`dashboards/${entityId}/permissions/group/`, { id }),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.allPermissions);
        },
    });
};

export const useDashboardPermissionsAddUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => apiPut(`dashboards/${entityId}/permissions/user/`, { id }),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.allPermissions);
        },
    });
};

export const useDashboardPermissionsRemoveGroup = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => apiDelete(`dashboards/${entityId}/permissions/group/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.allPermissions);
        },
    });
};

export const useDashboardPermissionsRemoveUser = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ entityId, id }: PermissionBasicRequestParams) => apiDelete(`dashboards/${entityId}/permissions/user/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.allPermissions);
        },
    });
};

export const useLatestViewedDashboards = (enabled?: boolean) => {
    return useQuery({
        queryKey: DashboardsQueryKeys.viewed,
        queryFn: ({ signal }) => apiGet<LatestViewedDashboardsDto>("dashboards/viewed", { signal }),
        enabled,
    });
};

export const useDashboardViewTracking = (dashboardId?: number): void => {
    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: (dashboardId: number) => apiPost<BasicMessageDto>(`dashboards/${dashboardId}/views`),
        onSuccess: () => {
            queryClient.invalidateQueries(DashboardsQueryKeys.viewed);
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_DASHBOARD,
        },
    });

    useEffect(() => {
        if (dashboardId !== undefined) {
            mutate(dashboardId);
        }
    }, [dashboardId, mutate]);
};
