import AddIcon from "@mui/icons-material/AddRounded";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
    Alert,
    Avatar,
    AvatarGroup,
    Badge,
    Button,
    Grid,
    IconButton,
    Skeleton,
    SkeletonProps,
    Snackbar,
    Stack,
    Tooltip,
    avatarClasses,
    badgeClasses,
    styled,
} from "@mui/material";
import { AclNamespaces, AclPermissions, CreateWidgetRequestBodyDto, DashboardDto, DashboardLayout, UserDto, UserTier } from "api-shared";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import BannerLayout from "../../components/BannerLayout";
import ItemNotFound from "../../components/ItemNotFound.tsx";
import DialogBackButton from "../../components/dialogues/DialogBackButton.tsx";
import UserEntryWithPopup from "../../components/user/UserEntryWithPopup.tsx";
import { UserGroupPermissionsDialog } from "../../components/usergrouppermissions/UserGroupPermissionsDialog";
import {
    useAddDashboardWidgetMutation,
    useDashboardPermissionsAddGroup,
    useDashboardPermissionsAddUser,
    useDashboardPermissionsRemoveGroup,
    useDashboardPermissionsRemoveUser,
    useDashboardViewTracking,
    useDashboards,
    useGetDashboardPermissions,
    useLatestViewedDashboards,
    useSaveDashboardLayoutMutation,
} from "../../domain/dashboards";
import { Endpoint, useEndpointQuery } from "../../domain/endpoint";
import { useUserHasAccessPermissionQuery, useUsersHavingAccessPermissionQuery } from "../../domain/permissions.ts";
import { useUiState } from "../../domain/ui-state";
import { useCurrentUser, useUser } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { NotificationCustomAction, showNotificationEvent } from "../../infrastructure/notifications";
import { trackEvent } from "../../infrastructure/tracking";
import { useFieldDataSubscription } from "../../lib/field-options";
import { NotificationType } from "../../lib/notifications";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import Dashboard from "./Dashboard";
import DashboardContextMenu from "./DashboardContextMenu";
import DashboardSelect from "./DashboardSelect";
import DashboardSkeleton from "./DashboardSkeleton";
import WidgetTemplateDialog from "./WidgetTemplateDialog";
import { getWidgetId, handleScrollToElement } from "./utils";

const Header = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 6),
    // use fixed height + flexbox instead of "magic" paddings that add up with the children paddings to the desired values
    height: theme.spacing(8),
}));

const FullHeightGrid = styled(Grid)({
    height: "100%",
});
const NoShrinkGrid = styled(Grid)({
    flexShrink: 0,
});

const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 4, 0),
    overflowX: "hidden", // avoid endless horizontal dragging
    height: "100%", // claim all available height, so that vertical dragging with scrolling is less glitchy
}));

const MoreMenuSkeleton = () => (
    <Skeleton variant="circular">
        <IconButton sx={{ p: 0.75 }}>
            <MoreVertRoundedIcon />
        </IconButton>
    </Skeleton>
);

const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
    [`& .${avatarClasses.root}, & .${"UserAvatar"}`]: {
        border: `1px solid ${theme.palette.common.white}`,
        height: theme.spacing(4.25),
        width: theme.spacing(4.25),
    },
}));

const ResponsibleBadge = styled(Badge)(({ theme }) => ({
    border: "none",
    color: theme.palette.background.paper,
    [`& .${badgeClasses.badge}`]: {
        bottom: "38%",
        right: "38%",
    },
}));

const StyledLockIcon = styled(LockIcon)(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    borderRadius: theme.spacing(0.4),
    padding: theme.spacing(0.1),
    height: theme.spacing(1.3),
    width: theme.spacing(1.3),
}));

const LineSkeleton = (props: SkeletonProps) => <Skeleton variant="text" sx={{ fontSize: "h5.fontSize" }} {...props} />;

const DashboardsView = () => {
    const { t: translate } = useTranslation();

    const params = useParams<{ id: string }>();

    useDocumentTitle(translationKeys.VDLANG_SECTIONS_DASHBOARD);

    const user = useCurrentUser();
    const dashboardsQuery = useDashboards();
    const widgetTemplatesQuery = useEndpointQuery(Endpoint.WidgetTemplates);
    const addWidgetMutation = useAddDashboardWidgetMutation();
    const saveDashboardLayoutMutation = useSaveDashboardLayoutMutation();
    const dashboardPermissionsAddGroup = useDashboardPermissionsAddGroup();
    const dashboardPermissionsAddUser = useDashboardPermissionsAddUser();
    const dashboardPermissionsDeleteGroup = useDashboardPermissionsRemoveGroup();
    const dashboardPermissionsDeleteUser = useDashboardPermissionsRemoveUser();

    // Some of the widgets use field data from OptionsProvider, which require the subscription to be in place so that all data is there
    useFieldDataSubscription();

    const [uiState, updateUiState] = useUiState();
    const paramDashboardId = params.id !== undefined && !isNaN(+params.id) ? +params.id : (uiState.lastDashboardId ?? undefined);

    const widgetTemplateDialog = useDialog();

    const userGroupPermissionsDialog = useDialog();

    const [selectedDashboardId, setSelectedDashboardId] = useState<number | undefined>(paramDashboardId);
    const dispatch = useDispatch();

    const hasDashboardAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Dashboard);
    const havingDashboardAccessPermissionQuery = useUsersHavingAccessPermissionQuery(AclNamespaces.Dashboard);

    useEffect(() => {
        if (!dashboardsQuery.isSuccess || dashboardsQuery.data.length < 1 || dashboardsQuery.isFetching) {
            return;
        }

        const hasNoSelectedDashboard = selectedDashboardId === undefined;

        if (hasNoSelectedDashboard) {
            const firstDashboard = dashboardsQuery.data[0];
            const firstOwnDashboard = dashboardsQuery.data.filter((dashboard) => dashboard.userId === user?.id)[0] ?? firstDashboard;
            setSelectedDashboardId(firstOwnDashboard.id);
            updateUiState({ lastDashboardId: firstOwnDashboard.id });
        }
    }, [dashboardsQuery.data, dashboardsQuery.isFetching, dashboardsQuery.isSuccess, selectedDashboardId, updateUiState, user?.id]);

    const dashboard = dashboardsQuery.data?.find(({ id }) => id === selectedDashboardId);
    const [hintClosed, setHintClosed] = useState(dashboard?.userId === user?.id);

    const dashboardUser = useUser(dashboard?.userId ?? null);

    useDashboardViewTracking(dashboard?.id);
    const dashboardViews = useLatestViewedDashboards(dashboardsQuery.isSuccess);

    const permissions = useGetDashboardPermissions({
        permission: AclPermissions.Read,
        enabled: dashboard !== undefined,
        dashboardId: dashboard?.id ?? -1,
    });

    const hasDashboardGroupUsers = permissions.data?.groupAcls.length !== 0;
    const hasDashboardUsers = permissions.data?.userAcls.filter((user) => user.userId !== dashboard?.userId).length !== 0;
    const showVisibilityBadge = !(hasDashboardGroupUsers || hasDashboardUsers);

    const saveDashboardLayout = useCallback(
        (newLayout: DashboardLayout) => {
            if (dashboard?.id === undefined) {
                return;
            }
            saveDashboardLayoutMutation.mutate({ dashboardId: dashboard.id, layout: newLayout });
        },
        [dashboard?.id, saveDashboardLayoutMutation],
    );

    if ((params.id === undefined || +params.id !== selectedDashboardId) && selectedDashboardId !== undefined) {
        return <Navigate replace to={RouteFor.dashboard.forId(selectedDashboardId)} />;
    }

    const firstDashboardId = dashboardsQuery.isSuccess ? dashboardsQuery.data[0].id : undefined;
    const onClick = () => {
        setSelectedDashboardId(firstDashboardId);
        firstDashboardId && updateUiState({ lastDashboardId: firstDashboardId });
    };

    const hasUnknownSelectedDashboard = dashboardsQuery.data?.findIndex(({ id }) => id === selectedDashboardId) === -1;

    const showHint = (widgetId: number) => {
        const widgetName = getWidgetId(widgetId);
        const actions: NotificationCustomAction = {
            label: translationKeys.VDLANG_DASHBOARDS_DUPLICATE_WIDGET_BUTTON_LABEL,
            onClick: () => handleScrollToElement(widgetName),
        };

        dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_WIDGET_CREATED, {}, actions));
    };
    const addDashboardWidget = (data: CreateWidgetRequestBodyDto) => {
        const { name, description, type, config } = data;
        if (dashboard !== undefined) {
            addWidgetMutation.mutate(
                { dashboardId: dashboard.id, name, description, type, config },
                { onSuccess: (response) => showHint(response.id) },
            );
            trackEvent({ category: "Dashboard", action: "Widget Created", name });
        }

        widgetTemplateDialog.close();
    };
    const handleDashboardChange = (dashboard: DashboardDto) => {
        setSelectedDashboardId(dashboard.id);
        updateUiState({ lastDashboardId: dashboard.id });
        trackEvent({ category: "Dashboard", action: "Dashboard Selected" });
        if (user?.id !== dashboard.userId) {
            setHintClosed(false);
        }
    };

    const handleDashboardDelete = () => {
        setSelectedDashboardId(undefined);
    };

    const isDashboardOwner = user != null && dashboard != null && dashboard.userId === user.id;
    const isLastOwnedDashboard = (dashboardsQuery.data ?? []).filter((dashboard) => dashboard.userId === user?.id).length <= 1;

    if (!hasDashboardAccessPermissionQuery.isSuccess || !havingDashboardAccessPermissionQuery.isSuccess) {
        return null;
    }

    const filterAddableUsers = (u: UserDto) =>
        [UserTier.SuperAdmin, UserTier.Admin, UserTier.Advanced].includes(u.tier) &&
        havingDashboardAccessPermissionQuery.data.combinedUserIds.includes(u.id);

    const handleOpenAccessDialog = () => {
        trackEvent({ category: "Dashboard", action: "Access Dialog Opened" });

        userGroupPermissionsDialog.open();
    };

    return (
        <BannerLayout
            banner={
                <Header>
                    <FullHeightGrid container justifyContent="space-between" alignItems="center" wrap="nowrap">
                        <Grid item zeroMinWidth>
                            {dashboardsQuery.isSuccess && dashboardViews.isSuccess ? (
                                <DashboardSelect
                                    value={dashboard}
                                    dashboards={dashboardsQuery.data}
                                    dashboardViews={dashboardViews.data}
                                    onChange={handleDashboardChange}
                                />
                            ) : (
                                <LineSkeleton width={200} />
                            )}
                        </Grid>
                        <NoShrinkGrid item>
                            <Stack direction="row" spacing={1}>
                                {!dashboardsQuery.isSuccess ? (
                                    <>
                                        <LineSkeleton width={100} />
                                        <LineSkeleton width={100} />
                                        <MoreMenuSkeleton />
                                    </>
                                ) : null}
                                {showVisibilityBadge ? (
                                    <StyledAvatarGroup>
                                        <Tooltip title={translate(translationKeys.VDLANG_DASHBOARD_ACCESS_CONTROL_PRIVATE)}>
                                            <ResponsibleBadge
                                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                                                badgeContent={<StyledLockIcon />}
                                            >
                                                <Avatar>
                                                    <VisibilityOutlinedIcon />
                                                </Avatar>
                                            </ResponsibleBadge>
                                        </Tooltip>
                                        <UserEntryWithPopup iconOnly user={dashboardUser} />
                                    </StyledAvatarGroup>
                                ) : null}
                                {!showVisibilityBadge && !hasUnknownSelectedDashboard ? (
                                    <Tooltip title={translate(translationKeys.VDLANG_DASHBOARDS_DASHBOARD_OWNER)}>
                                        <UserEntryWithPopup avatarProps={{ size: 36 }} iconOnly user={dashboardUser} />
                                    </Tooltip>
                                ) : null}
                                {dashboard !== undefined && (
                                    <DialogBackButton
                                        key="back"
                                        startIcon={<SupervisorAccountIcon fontSize="medium" />}
                                        onClick={handleOpenAccessDialog}
                                        name={translate(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_BUTTON)}
                                    />
                                )}
                                {isDashboardOwner && (
                                    <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={widgetTemplateDialog.open}
                                        disabled={dashboard === undefined}
                                    >
                                        {translate(translationKeys.VDLANG_DASHBOARDS_ADD_WIDGET_BUTTON_LABEL)}
                                    </Button>
                                )}
                                {dashboard !== undefined ? (
                                    <DashboardContextMenu
                                        dashboard={dashboard}
                                        disabledDelete={isLastOwnedDashboard}
                                        isDashboardOwner={isDashboardOwner}
                                        onDashboardDuplicated={handleDashboardChange}
                                        onDashboardDelete={handleDashboardDelete}
                                    />
                                ) : null}
                            </Stack>
                        </NoShrinkGrid>
                    </FullHeightGrid>
                </Header>
            }
        >
            <Content>
                {dashboardsQuery.isSuccess && hasUnknownSelectedDashboard ? (
                    <ItemNotFound toolTip={translationKeys.VDLANG_DASHBOARDS_NOT_FOUND} onClick={onClick} />
                ) : null}
                {dashboardsQuery.isSuccess && !hasUnknownSelectedDashboard ? (
                    <Snackbar
                        autoHideDuration={10000}
                        sx={{ maxWidth: (theme) => theme.spacing(70) }}
                        onClose={() => setHintClosed(true)}
                        open={user?.id !== dashboard?.userId && !hintClosed}
                    >
                        <Alert
                            severity="info"
                            action={
                                <Button
                                    variant="text"
                                    sx={{ color: (theme) => theme.palette.info.dark, alignSelf: "center" }}
                                    onClick={() => setHintClosed(true)}
                                >
                                    {translate(translationKeys.VDLANG_DASHBOARDS_PERMISSIONS_ACCEPT_BUTTON_LABEL)}
                                </Button>
                            }
                        >
                            {translate(FeedbackTranslationKeys.VDLANG_FEEDBACK_DASHBOARD_PERMISSIONS_NEEDED)}
                        </Alert>
                    </Snackbar>
                ) : null}
                {widgetTemplatesQuery.data !== undefined ? (
                    <WidgetTemplateDialog
                        open={widgetTemplateDialog.isOpen}
                        onClose={widgetTemplateDialog.close}
                        widgetTemplates={widgetTemplatesQuery.data}
                        translate={translate}
                        addDashboardWidget={addDashboardWidget}
                    />
                ) : null}
                {!dashboardsQuery.isSuccess ? <DashboardSkeleton /> : null}
                {dashboard !== undefined && (
                    <Dashboard dashboard={dashboard} onLayoutChanged={saveDashboardLayout} disabled={!isDashboardOwner} />
                )}
                {permissions.data !== undefined && dashboard !== undefined ? (
                    <UserGroupPermissionsDialog
                        open={userGroupPermissionsDialog.isOpen}
                        onClose={userGroupPermissionsDialog.close}
                        permissions={permissions.data}
                        entityId={dashboard.id}
                        ownerId={dashboardUser?.id ?? -1}
                        readonly={!isDashboardOwner}
                        filterAddableUsers={filterAddableUsers}
                        addGroup={(e, g) => dashboardPermissionsAddGroup.mutate({ entityId: e, id: g })}
                        addUser={(e, u) => dashboardPermissionsAddUser.mutate({ entityId: e, id: u })}
                        deleteGroup={(e, g) => dashboardPermissionsDeleteGroup.mutate({ entityId: e, id: g })}
                        deleteUser={(e, u) => dashboardPermissionsDeleteUser.mutate({ entityId: e, id: u })}
                    />
                ) : null}
            </Content>
        </BannerLayout>
    );
};

export default DashboardsView;
