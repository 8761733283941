import FunctionsIcon from "@mui/icons-material/FunctionsRounded";
import { Typography, styled, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { DefaultScopeEndDate, DefaultScopeStartDate, FilterDefinition, ScopeDto, WidgetDto } from "api-shared";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useClientFiscalYear } from "../../domain/client";
import { useMeasuresPotential } from "../../domain/measure/potential";
import useCurrency from "../../hooks/useCurrency";
import useTimezone from "../../hooks/useTimezone";
import { getFiscalYearText } from "../../lib/fiscal-units";
import { translationKeys } from "../../translations/main-translations";
import WidgetError from "./WidgetError";

const GradientDivider = styled("div")({
    width: "100%",
    height: "1px",
    background: `radial-gradient(circle at 50% -0.01%, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.00) 100%)`,
});

const PotentialStack = styled(Stack)({
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    height: "100%",
});

const getEffectColor = (value: number, theme: Theme) => {
    if (value > 0) {
        return theme.palette.dashboardGreen.dark;
    }
    if (value < 0) {
        return theme.palette.dashboardRed.dark;
    }
    return theme.palette.defaultDark.dark;
};

type PotentialKPIProps = {
    widget: WidgetDto;
    filter: FilterDefinition;
    scope: ScopeDto;
    enableQueries: boolean;
};

const PotentialKPI = ({ widget, filter, scope, enableQueries = true }: PotentialKPIProps) => {
    const { formatCurrencyShort } = useCurrency();
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const { formatDate } = useTimezone();
    const fiscalYearStart = useClientFiscalYear();

    const measuresQuery = useMeasuresPotential(
        `widget_${widget.id}`,
        {
            filter,
            scope,
        },
        enableQueries,
        {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    );

    const getTimeRangeString = () => {
        if (scope.startDate === null || scope.endDate === null) {
            return translate(translationKeys.VDLANG_TIME_RANGE_SELECT_WITHOUT_TIME_RANGE);
        }
        if (scope.startDate === DefaultScopeStartDate && scope.endDate === DefaultScopeEndDate) {
            // All fiscal years
            return translate("total_time_range");
        }
        if (
            new Date(scope.startDate).getMonth() === fiscalYearStart &&
            moment(scope.endDate).diff(moment(scope.startDate), "month") === 11
        ) {
            return getFiscalYearText(moment(scope.startDate), fiscalYearStart, translate);
        }
        return `${formatDate(scope.startDate)} - ${formatDate(scope.endDate)}`;
    };

    if (measuresQuery.isLoading) {
        return <LoadingAnimation />;
    }

    if (!measuresQuery.isSuccess || measuresQuery.data.sums.effect === undefined) {
        return <WidgetError />;
    }

    return (
        <PotentialStack spacing={2} justifyContent="center" alignItems="center" divider={<GradientDivider />}>
            <FunctionsIcon />
            <Stack spacing={0} justifyContent="center">
                <Typography align="center" variant="h5" color={getEffectColor(measuresQuery.data.sums.effect, theme)}>
                    {formatCurrencyShort(measuresQuery.data.sums.effect)}
                </Typography>
                <Typography align="center" variant="caption">
                    {getTimeRangeString()}
                </Typography>
            </Stack>
        </PotentialStack>
    );
};

export default PotentialKPI;
